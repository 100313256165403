import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "My Favorite Movies of 2014",
  "date": "2014-12-30T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As we approach the end of 2014, I thought it’d be a good idea to list out the movies I have watched this year that I’ve enjoyed the most. This is by no means an exhaustive list and it’s ranked in no particular order.`}</p>
    <h3>{`The Grand Budapest Hotel`}</h3>
    <p><img parentName="p" {...{
        "src": "/assets/articles/my-favorite-movies-of-2014/the-grand-budapest-hotel.jpg",
        "alt": null
      }}></img></p>
    <p>{`God I love this movie. Every shot has been carefully crafted by Wes Anderson to tell a wonderful story. While watching the movie, you can experience Anderson’s universe and be a part of it for a moment.`}</p>
    <h3>{`Enemy`}</h3>
    <p><img parentName="p" {...{
        "src": "/assets/articles/my-favorite-movies-of-2014/enemy.jpg",
        "alt": null
      }}></img></p>
    <p>{`Upon first watch, this movie makes no sense, but the ending kept me thinking. I ended up watching this video on YouTube that explains the movie, and once you understand all the references, this movie is genius. A must watch.`}</p>
    <h3>{`Gone Girl`}</h3>
    <p><img parentName="p" {...{
        "src": "/assets/articles/my-favorite-movies-of-2014/gone-girl.jpg",
        "alt": null
      }}></img></p>
    <p>{`Fantastic movie that keeps you on the edge of your seat the whole time. The ending is beautifully unexpected and shows some great acting by Ben Affleck. I wish I could re-live the first time I watched this movie.`}</p>
    <h3>{`Guardians of the Galaxy`}</h3>
    <p><img parentName="p" {...{
        "src": "/assets/articles/my-favorite-movies-of-2014/guardians-of-the-galaxy.jpg",
        "alt": null
      }}></img></p>
    <p>{`While it’s definitely not in the same category as some of the other movies mentioned previously, it was just a really fun action movie. It reminds me in a way of the first Star Wars. I’ll be looking forward to the next one.`}</p>
    <hr></hr>
    <p>{`At this point you must be wondering why Interstellar isn’t there. From what I’ve heard it’s really great, but I haven’t had the time to watch it yet. If I did watch it, I’m sure it would be up there. Any other movies that slipped under my radar this year? `}<a parentName="p" {...{
        "href": "https://twitter.com/vernalkick"
      }}>{`Let me know!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      